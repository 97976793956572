import {
	Chip,
	Grid,
	Typography,
	Avatar,
	Link,
	Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { publicationTypesMapping } from "../utils/publication-constants.js";

const useStyles = makeStyles((theme) => ({
	buttonCursor: {
		cursor: "pointer",
	},
	select: {
		color: "#F1A542", // Set the desired color for the select
		"&:before": {
			borderColor: "#F1A542", // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: "#F1A542", // Set the desired color for the select's border when focused
		},
	},
	link: {
		color: theme.palette.third.main,
		textDecoration: "underline",
		"&:hover": {
			color: theme.palette.third.dark,
			textDecoration: "underline",
		},
	},
	teamChip: {
		backgroundColor: theme.palette.primary.main,
		"& .MuiChip-label": {
			color: "white",
		},
	},
	publicationTypeChip: {
		backgroundColor: theme.palette.green.main,
		"& .MuiChip-label": {
			color: "white",
		},
	},
	acceptedChip: {
		backgroundColor: theme.palette.success.main,
		"& .MuiChip-label": {
			color: "white",
		},
	},
	rejectedChip: {
		backgroundColor: theme.palette.error.main,
		"& .MuiChip-label": {
			color: "white",
		},
	},
	pendingChip: {
		backgroundColor: theme.palette.third.main,
		"& .MuiChip-label": {
			color: theme.palette.primary.main,
		},
	},
	previewAvatar: {
		backgroundColor: theme.palette.third.main,
		width: "38px",
		height: "38px",
		marginRight: "10px",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.third.dark,
		},
	},
	archiveAvatar: {
		backgroundColor: theme.palette.third.main,
		width: "38px",
		height: "38px",
		marginRight: "10px",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.warning.dark,
		},
	},
	unarchiveAvatar: {
		backgroundColor: theme.palette.success.main,
		width: "38px",
		height: "38px",
		marginRight: "10px",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.success.dark,
		},
	},
	deleteAvatar: {
		borderColor: theme.palette.error.main,
		color: theme.palette.error.main,
		backgroundColor: "transparent",
		border: "1px solid",
		width: "38px",
		height: "38px",
		cursor: "pointer",
		"&:hover": {
			borderColor: theme.palette.error.dark,
			color: theme.palette.error.dark,
		},
	},
}));

const createSupervisorString = (publication) => {
	let retString = "";
	if (!publication.orderedSupervisorsNames && publication.orderedSupervisorsNames.length === 0) {
		return retString;
	}

	const mainSupervisorExists = publication.orderedSupervisorsNames[0].isMain;
	const assistantSupervisors = publication.orderedSupervisorsNames.slice(mainSupervisorExists ? 1 : 0);
	if (mainSupervisorExists) {
		retString += `Supervisor: ${publication.orderedSupervisorsNames[0].name}`;
	}

	if (assistantSupervisors.length > 0) {
		retString += `${mainSupervisorExists ? " | " : ""}Assistant supervisors: `;
		for (let i = 0; i < assistantSupervisors.length; i++) {
			retString += assistantSupervisors[i].name;
			if (i !== assistantSupervisors.length - 1) {
				retString += ", ";
			}
		}
	}

	return retString;
};

export const PublicationsList = ({
	filteredPublications,
	setPublicationToDelete,
	setSelectedPublication,
	setDeletePublicationPopupOpen,
	setPublicationToArchive,
	setArchivePublicationPopupOpen,
	showTeam,
	publicPreview,
}) => {
	const navigate = useNavigate();
	const classes = useStyles();

	return (
		<Grid>
			{
				filteredPublications.length === 0 && (
					<Typography color="gray" pl={2} pb={1} pt={1}>
						{"No publications"}
					</Typography>
				)
			}
			<Grid>
				{filteredPublications.map((pub, ind) => (
					<Grid
						key={ind}
						item
						container
						flexDirection="row"
						justifyContent="flex-start"
						mb={1}
						sx={{
							borderTop: ind === 0 ? "" : "1px solid",
							borderColor: "gray",
							// padding: "0.5rem",
						}}
					>
						<Grid
							item
							container
							xs={0.4}
							justifyContent="center"
							alignContent="center"
							flexDirection="column"
							backgroundColor="third.main"
							mr={1}
						>
							<Typography
								fontSize="1rem"
								color="white.main"
								fontWeight={600}
							>
								{ind + 1 < 10 ? `0${ind + 1}` : ind + 1}
							</Typography>
						</Grid>
						<Grid
							item
							container
							xs={10}
							flexDirection="column"
						>
							<Grid
								flexDirection="column"
								alignItems="flex-start"
							>
								<Typography
									fontSize="1rem"
									color="green.main"
									fontWeight={600}
								>
									{`${pub.title}`}
								</Typography>
								<Typography fontStyle='italic' color="white.main" fontSize="0.9rem" mb={1}>
									{
										pub.orderedAuthorsNames.map((author, innerInd) => (
											<span key={author}>
												{author}
												{innerInd !== pub.orderedAuthorsNames.length - 1 && ", "}
											</span>
										))
									}
								</Typography>
								<Grid container flexDirection="row" sx={{ marginTop: "-0.5rem" }}>
									{pub.orderedSupervisorsNames && pub.orderedSupervisorsNames.length > 0 && (
										<Typography
											fontStyle="italic"
											color="white.main"
											fontSize="0.8rem"
											mb={0}
											marginRight='0.2rem'
										>
											{createSupervisorString(pub)}
										</Typography>
									)}
								</Grid>
							</Grid>
							{pub.state.state === "Published" && pub.publishedTarget !== undefined && pub.publishedTarget !== null && (
								<Typography color="white.main" fontSize="0.9rem" fontWeight="bold">
									{`Published at ${pub.publishedTarget}`}
								</Typography>
							)}
							{pub.state.state === "Published" && pub.publishedTarget !== undefined && pub.publishedTarget !== null && (
								<Typography color="white.main" fontSize="0.9rem">
									{`Publication information: `}
									{pub.volume ? `Volume ${pub.volume} ` : "Volume - "}
									{pub.issue ? `| Issue ${pub.issue} ` : "| Issue - "}
									{pub.pages ? `| Pages ${pub.pages} ` : "| Pages - "}
									{pub.quartile ? `| ${pub.quartile} ` : "| Q - "}
									{pub.impactFactor ? `| IF ${pub.impactFactor} ` : "| IF - "}
								</Typography>
							)}
							{pub.state.state === "Published" && pub.doi !== undefined && pub.doi !== null && (
								<Link href={pub.doi} target="_blank" rel="noopener" className={classes.link} color="third.main" fontSize="0.9rem" mb={0.5}>
									{`DOI: ${pub.doi}`}
								</Link>
							)}
							{pub.state.state !== "Published" && (
								<Typography color="white.main" fontSize="0.8rem" mb={0.5}>
									{`Last updated on ${pub.updatedAt.replace("T", " ").replace("Z", "")}`}
								</Typography>
							)}
							{pub.state.state !== "Published" && (
								<Grid
									item
									display="flex"
									flexDirection="row"
								>
									{
										showTeam === "true" && (
											<Chip label={`Team: ${pub.teamName}`} size="small" className={classes.teamChip} sx={{ mr: 1 }} />
										)
									}
									<Chip label={pub.publicationType} size="small" className={classes.publicationTypeChip} sx={{ mr: 1 }} />
									<Chip
										label={pub.state.state}
										size="small"
										className={
											pub?.state?.state === "Accepted" ? classes.acceptedChip
												: pub?.state?.state === "Rejected" ? classes.rejectedChip
													: classes.pendingChip
										}
									/>
								</Grid>
							)}
						</Grid>
						<Grid
							item
							container
							xs={1.5}
							justifyContent="flex-end"
							alignContent="center"
							flexDirection="row"
						>
							<Avatar
								className={classes.previewAvatar}
								onClick={() => {
									if (publicPreview === "true") {
										console.log(pub);
										setSelectedPublication(pub);
									} else {
										const _teamId = pub.team._id ?? pub.team;
										console.log("Going to move", pub);
										navigate(`/teams/${_teamId}/${publicationTypesMapping[pub.publicationType]}/${pub._id}`);
									}
								}}
							>
								<VisibilityIcon color="white" />
							</Avatar>
							{
								pub.state.state !== "Published" && (
									<Tooltip title={pub.isArchived ? "Unarchive" : "Archive"}>
										<Avatar
											className={pub.isArchived ? classes.unarchiveAvatar : classes.archiveAvatar}
											onClick={() => {
												setPublicationToArchive(pub);
												setArchivePublicationPopupOpen(true);
											}}
										>
											{ pub.isArchived ? <UnarchiveIcon /> : <ArchiveIcon /> }
										</Avatar>
									</Tooltip>
								)
							}
							{
								publicPreview === "false" && (
									<Avatar
										className={classes.deleteAvatar}
										onClick={() => {
											setPublicationToDelete(pub);
											setDeletePublicationPopupOpen(true);
										}}
									>
										<DeleteIcon />
									</Avatar>
								)
							}
						</Grid>
					</Grid>
				))}
			</Grid>
		</Grid >
	);
};
