import { useState } from "react";
import {
	Grid,
	Typography,
	FormControl,
	Select,
	MenuItem,
	Box,
} from "@mui/material";
import Popup from "../Popup.js";
import Form from "../Form.js";
import { addAssignment, updatePublication } from "../../api/index.js";
import { populateProjectWithTasks } from "../../utils/cycloptAPI.js";
import DatePicker from "../DatePicker.js";

const AddAssignmentPopup = ({
	publication,
	open,
	setOpen,
	classes,
	setIsLoading,
	success,
	error,
	fetchData,
	allPublicationAuthorsAndMembers,
}) => {
	const [newTeamMemberAsAssignment, setNewTeamMemberAsAssignment] = useState("");
	const [deadlineDate, setDeadlineDate] = useState("");

	const addAssignmentFormContent = [
		{
			customType: "wideInput",
			id: "text",
			type: "text",
			placeholder: "Describe the assignment",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Add assignment",
		},
	];

	const createNewTaskIntegration = async (newAssignment) => {
		// update integrations!
		const newTask = {
			_id: newAssignment._id,
			title: newAssignment.text,
			body: newAssignment.text ?? "",
		};

		// add new tasks to the new project selected
		const res = await populateProjectWithTasks(publication.cycloptToken, publication.cycloptProject.projectId, [newTask]);
		if (res.status !== 200) throw new Error("Error while linking tasks");

		console.log("old tasks:", publication.cycloptProject.links);
		// eslint-disable-next-line arrow-body-style
		const oldTasks = publication.cycloptProject.links.map((task) => {
			return {
				integrationId: task.integrationId,
				publisyncId: task.publisyncId,
			};
		});
		const newTasks = res.links;
		console.log("new tasks to add", newTasks);
		// append the new links to the old array;
		const taskLinks = [...newTasks, ...oldTasks]; // newTasks.concat(oldTasks);
		console.log("added tasks", taskLinks);
		const newCycloptProject = {
			projectId: publication.cycloptProject.projectId,
			links: taskLinks,
		};
		// eslint-disable-next-line max-len
		const { success: successCode, message } = await updatePublication(publication._id, publication.title, publication.doi, newCycloptProject);
		if (successCode) {
			success(message);
		} else {
			error(message);
		}
	};

	const addAssignmentSubmitHandler = async (values) => {
		setIsLoading(true);
		try {
			const { success: successCode, message, newAssignment } = await addAssignment(publication._id, newTeamMemberAsAssignment, values.text, deadlineDate === "" ? undefined : deadlineDate);
			console.log("new Assignment created!", newAssignment);
			await createNewTaskIntegration(newAssignment);
			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setNewTeamMemberAsAssignment("");
		setDeadlineDate("");
		setIsLoading(false);
		setOpen(false);
	};

	return (
		<Popup
			width="800px"
			open={open}
			title="Add assignment"
			onClose={() => {
				setOpen(false);
				setNewTeamMemberAsAssignment("");
				setDeadlineDate("");
			}}
		>
			<Grid
				container
				flexDirection="row"
				flex={1}
				sx={{
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
					mb: 1,
				}}
			>
				<Typography color="third.main">{"Choose author or member: "}</Typography>
				<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>

					<Select
						className={classes.select}
						value={newTeamMemberAsAssignment}
						onChange={(event) => {
							setNewTeamMemberAsAssignment(event.target.value);
						}}
					>
						{
							allPublicationAuthorsAndMembers.map((e, ind) => (
								<MenuItem key={ind} value={e._id}>{e.fullname}</MenuItem>
							))
						}
					</Select>
				</FormControl>
			</Grid>
			<Grid
				container
				flexDirection="row"
				flex={1}
				sx={{
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
					mb: 1,
				}}
			>
				<Typography color="third.main" sx={{ marginRight: "0.5rem" }}>{"Deadline: "}</Typography>

				<Box
					width="200px"
				>
					<DatePicker
						type="desktop"
						label=""
						value={deadlineDate}
						background="primary"
						color="third"
						onChange={(value) => {
							try {
								setDeadlineDate(`${value.$D}/${value.$M + 1}/${value.$y}`);
							} catch (error_) { console.log(error_); }
						}}
					/>
				</Box>
			</Grid>
			<Form
				content={addAssignmentFormContent}
				// validationSchema="addTeamMemberToPublicationSchema"
				onSubmit={addAssignmentSubmitHandler}
			/>
		</Popup>
	);
};

export default AddAssignmentPopup;
